
export default {
  name: 'SubMenu',
  components: {
    Wrapper: () => import('@/components/Structure/Wrapper')
  },
  props: {
    category: {
      type: String,
      required: true
    },
    inside: {
      type: Object,
      default() {}
    },
    customClass: {
      type: String
    },
    showMenu: {
      type: Boolean,
      default: false
    }
  }
}
